import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store/index";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: () =>
      import(/* webpackChunkName: "Default" */ "@/layouts/Default.vue"),
    meta: { requiresLogin: true },
    children: [
      {
        path: "funcionarios",
        name: "Funcionarios",
        meta: { permission: "employee.search" },
        component: () =>
          import(
            /* webpackChunkName: "Funcionarios" */ "@/views/Funcionarios.vue"
          ),
      },
      {
        path: "funcionario/:id",
        name: "FuncionarioEditar",
        meta: { permission: "employee.edit" },
        component: () =>
          import(
            /* webpackChunkName: "FuncionarioEditar" */ "@/views/FuncionarioEditar.vue"
          ),
      },
      {
        path: "forca-de-vendas",
        name: "ForcaDeVendas",
        meta: { permission: "saleForce.search" },
        component: () =>
          import(
            /* webpackChunkName: "ForcaDeVendas" */ "@/views/ForcaDeVendas.vue"
          ),
      },
      {
        path: "forca-de-venda/:id",
        name: "ForcaDeVendasExibir",
        meta: { permission: "saleForce.show" },
        component: () =>
          import(
            /* webpackChunkName: "ForcaDeVendasExibir" */ "@/views/ForcaDeVendasExibir.vue"
          ),
      },
      {
        path: "anatomias-fisiologias",
        name: "AnatomiasFisiologias",
        meta: { permission: "anatomyPhysiology.search" },
        component: () =>
          import(
            /* webpackChunkName: "AnatomiasFisiologias" */ "@/views/AnatomiasFisiologias.vue"
          ),
      },
      {
        path: "anatomia-fisiologia-criar",
        name: "AnatomiasFisiologiasCriar",
        meta: { permission: "anatomyPhysiology.create" },
        component: () =>
          import(
            /* webpackChunkName: "AnatomiasFisiologiasCriar" */ "@/views/AnatomiasFisiologiasCriar.vue"
          ),
      },
      {
        path: "anatomia-fisiologia/:id",
        name: "AnatomiasFisiologiasEditar",
        meta: { permission: "anatomyPhysiology.edit" },
        component: () =>
          import(
            /* webpackChunkName: "AnatomiasFisiologiasEditar" */ "@/views/AnatomiasFisiologiasEditar.vue"
          ),
      },
      {
        path: "patologias",
        name: "Patologias",
        meta: { permission: "pathology.search" },
        component: () =>
          import(/* webpackChunkName: "Patologias" */ "@/views/Patologias.vue"),
      },
      {
        path: "patologia/:id",
        name: "PatologiaEditar",
        meta: { permission: "pathology.edit" },
        component: () =>
          import(
            /* webpackChunkName: "PatologiaEditar" */ "@/views/PatologiaEditar.vue"
          ),
      },
      {
        path: "patologia-criar",
        name: "PatologiaCriar",
        meta: { permission: "pathology.create" },
        component: () =>
          import(
            /* webpackChunkName: "PatologiaCriar" */ "@/views/PatologiaCriar.vue"
          ),
      },
      {
        path: "especialidades",
        name: "Especialidades",
        meta: { permission: "especialty.search" },
        component: () =>
          import(
            /* webpackChunkName: "Especialidades" */ "@/views/Especialidades.vue"
          ),
      },
      {
        path: "especialidade/:id",
        name: "EspecialidadeEditar",
        meta: { permission: "especialty.edit" },
        component: () =>
          import(
            /* webpackChunkName: "EspecialidadeEditar" */ "@/views/EspecialidadeEditar.vue"
          ),
      },
      {
        path: "especialidade-criar",
        name: "EspecialidadeCriar",
        meta: { permission: "especialty.create" },
        component: () =>
          import(
            /* webpackChunkName: "EspecialidadeCriar" */ "@/views/EspecialidadeCriar.vue"
          ),
      },
      {
        path: "laboratorios",
        name: "Laboratorios",
        meta: { permission: "laboratory.search" },
        component: () =>
          import(
            /* webpackChunkName: "Laboratorios" */ "@/views/Laboratorios.vue"
          ),
      },
      {
        path: "laboratorio/:id",
        name: "LaboratorioEditar",
        meta: { permission: "laboratory.edit" },
        component: () =>
          import(
            /* webpackChunkName: "LaboratorioEditar" */ "@/views/LaboratorioEditar.vue"
          ),
      },
      {
        path: "laboratorio-criar",
        name: "LaboratorioCriar",
        meta: { permission: "laboratory.create" },
        component: () =>
          import(
            /* webpackChunkName: "LaboratorioCriar" */ "@/views/LaboratorioCriar.vue"
          ),
      },
      {
        path: "linhas",
        name: "Linhas",
        meta: { permission: "line.search" },
        component: () =>
          import(/* webpackChunkName: "Linhas" */ "@/views/Linhas.vue"),
      },
      {
        path: "linha/:id",
        name: "LinhaEditar",
        meta: { permission: "line.edit" },
        component: () =>
          import(
            /* webpackChunkName: "LinhaEditar" */ "@/views/LinhaEditar.vue"
          ),
      },
      {
        path: "linha-criar",
        name: "LinhaCriar",
        meta: { permission: "line.create" },
        component: () =>
          import(/* webpackChunkName: "LinhaCriar" */ "@/views/LinhaCriar.vue"),
      },
      {
        path: "classificacoes",
        name: "Classificacoes",
        meta: { permission: "classification.search" },
        component: () =>
          import(
            /* webpackChunkName: "Classificacoes" */ "@/views/Classificacoes.vue"
          ),
      },
      {
        path: "classificacao/:id",
        name: "ClassificacaoEditar",
        meta: { permission: "classification.edit" },
        component: () =>
          import(
            /* webpackChunkName: "ClassificacaoEditar" */ "@/views/ClassificacaoEditar.vue"
          ),
      },
      {
        path: "classificacao-criar",
        name: "ClassificacaoCriar",
        meta: { permission: "classification.create" },
        component: () =>
          import(
            /* webpackChunkName: "ClassificacaoCriar" */ "@/views/ClassificacaoCriar.vue"
          ),
      },
      {
        path: "/",
        name: "Produtos",
        meta: { permission: "product.search" },
        component: () =>
          import(/* webpackChunkName: "Produtos" */ "@/views/Produtos.vue"),
      },
      {
        path: "produto-criar",
        name: "ProdutoCriar",
        meta: { permission: "product.create" },
        component: () =>
          import(
            /* webpackChunkName: "ProdutoCriar" */ "@/views/ProdutoCriar.vue"
          ),
      },
      {
        path: "produto-visualizar/:id",
        name: "ProdutoVisualizar",
        meta: { permission: "product.show" },
        component: () =>
          import(
            /* webpackChunkName: "ProdutoVisualizar" */ "@/views/ProdutoVisualizar.vue"
          ),
      },
      {
        path: "produto-editar/:id",
        name: "ProdutoEditar",
        meta: { permission: "product.edit" },
        component: () =>
          import(
            /* webpackChunkName: "ProdutoEditar" */ "@/views/ProdutoEditar.vue"
          ),
      },
      {
        path: "notificacoes",
        name: "Notificacoes",
        meta: { permission: "notification.search" },
        component: () =>
          import(
            /* webpackChunkName: "Notificacoes" */ "@/views/Notificacoes.vue"
          ),
      },
      {
        path: "notificacao-criar",
        name: "NotificacaoCriar",
        meta: { permission: "notification.create" },
        component: () =>
          import(
            /* webpackChunkName: "NotificacaoCriar" */ "@/views/NotificacaoCriar.vue"
          ),
      },
      {
        path: "notificacao-visualizar/:id",
        name: "NotificacaoVisualizar",
        meta: { permission: "notification.show" },
        component: () =>
          import(
            /* webpackChunkName: "NotificacaoVisualizar" */ "@/views/NotificacaoVisualizar.vue"
          ),
      },
      {
        path: "notificacao-editar/:id",
        name: "NotificacaoEditar",
        meta: { permission: "notification.export" },
        component: () =>
          import(
            /* webpackChunkName: "NotificacaoEditar" */ "@/views/NotificacaoEditar.vue"
          ),
      },
      {
        path: "relatorio-tempo-de-visualizacao-de-produto",
        name: "RelatorioTempoVisualizacaoProduto",
        meta: { permission: "statisticProduct.export" },
        component: () =>
          import(
            /* webpackChunkName: "RelatorioTempoVisualizacaoProduto" */ "@/views/RelatorioTempoVisualizacaoProduto.vue"
          ),
      },
      {
        path: "relatorio-quantidade-de-visualizacao-de-produto",
        name: "RelatorioQuantidadeVisualizacaoProduto",
        meta: { permission: "statisticProduct.export" },
        component: () =>
          import(
            /* webpackChunkName: "RelatorioQuantidadeVisualizacaoProduto" */ "@/views/RelatorioQuantidadeVisualizacaoProduto.vue"
          ),
      },
      {
        path: "relatorio-quantidade-de-termos-pesquisados",
        name: "RelatorioQuantidadeTermosPesquisados",
        meta: { permission: "statisticTerm.export" },
        component: () =>
          import(
            /* webpackChunkName: "RelatorioQuantidadeTermosPesquisados" */ "@/views/RelatorioQuantidadeTermosPesquisados.vue"
          ),
      },
    ],
  },
  {
    path: "/logincheck",
    name: "Login",
    meta: { isLoged: true },
    component: () =>
      import(/* webpackChunkName: "Login" */ "@/views/Login.vue"),
  },
  {
    path: "/sso",
    name: "LoginSSO",
    component: () =>
      import(/* webpackChunkName: "Login" */ "@/views/LoginSSO.vue"),
  },
  {
    path: "*",
    name: "NotFound",
    component: () =>
      import(/* webpackChunkName: "NotFound" */ "@/views/NotFound.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresLogin)) {
    if (to.matched.some((record) => record.meta.permission) && store.state.user.data.length) {
      store.state.user.data.permissions.includes(to.meta.permission)
        ? next()
        : next({ path: "/" });
    } else {
      store.state.user.loggedIn ? next() : window.location = process.env.VUE_APP_LOGIN
    }
  } else if (to.matched.some((record) => record.meta.isLoged)) {
    !store.state.user.loggedIn ? next() : next({ path: "/" });
  } else {
    next();
  }
});

export default router;
